import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function FloatingLabel(props) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const shouldBeScrolled = scrollTop > 0;
      setIsScrolled(shouldBeScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div
      className={`floatingLabel ${
        isScrolled ? 'scrolled' : ''
      } d-none d-md-block`}
    >
      <h4 className='heading4 text-center floatingLabelText'>
        Evidence <br />
        Based Consulting
      </h4>
    </div>
  );
}

FloatingLabel.propTypes = {};

export default FloatingLabel;
