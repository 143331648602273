import {
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGIN_ERROR,
  LOG_OUT,
  SET_USER_PROFILE,
} from '../actions/type';
import axios from 'axios';

const initialState = {
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true', // Retrieve from local storage
  token: localStorage.getItem('token') || null,
  userDetail: null,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('token', payload.token);
      localStorage.setItem('isAuthenticated', true);
      // localStorage.setItem('', action.payload.role);
      return {
        ...state,
        token: payload.token,
        isAuthenticated: true,
      };
    case AUTH_ERROR:
    case LOGIN_ERROR:
    case LOG_OUT:
      delete axios.defaults.headers.common['Authorization'];
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      // localStorage.removeItem('id');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userDetail: payload,
      };
    default:
      return state;
  }
}
