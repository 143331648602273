import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, auth, ...rest }) =>
  localStorage.getItem('token') || auth.isAuthenticated ? (
    children
  ) : (
    <Navigate to='/authentication/on-boarding' />
  );

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
