// import './App.css';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import setAuthToken from './utils/setAuthToken';
import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

// Redux
import { Provider } from 'react-redux';
import store from './store';

import { Row, Col, Container } from 'react-bootstrap';

import ScrollToTop from './components/layouts/ScrollToTop';

import PrivateRoute from './utils/PrivateRoute';
import FloatingLabel from './components/layouts/FloatingLabel';

const Header = React.lazy(() => import('./components/layouts/Header'));

const Footer = React.lazy(() => import('./components/layouts/Footer'));

const NotFound = React.lazy(() => import('./components/layouts/NotFound'));
const Home = React.lazy(() => import('./components/pre-login/Home'));
const Services = React.lazy(() => import('./components/pre-login/Services'));

const OurWay = React.lazy(() => import('./components/pre-login/OurWay'));
const SecurityCompliance = React.lazy(() =>
  import('./components/pre-login/SecurityCompliance')
);
const ContactUs = React.lazy(() => import('./components/pre-login/ContactUs'));
const AboutUs = React.lazy(() => import('./components/pre-login/AboutUs'));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  useEffect(() => {
    if (localStorage.token) {
      // store.dispatch(getUserProfile(localStorage.token));
    }
  }, []);

  const PreLoginContainer = () => (
    <Fragment>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/services' element={<Services />}></Route>
        <Route path='/our-way' element={<OurWay />}></Route>
        <Route
          path='/security-and-compliance'
          element={<SecurityCompliance />}
        ></Route>
        <Route path='/contact-us' element={<ContactUs />}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
        <Route path='*' element={<Navigate to='/404' />}></Route>
      </Routes>
      <Footer />
      {/* <FloatingLabel /> */}
    </Fragment>
  );

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Suspense
            fallback={
              <div className='suspenseLoader'>
                <span className='loader'></span>
              </div>
            }
          >
            <Routes>
              <Route path='/*' element={<PreLoginContainer />}></Route>

              <Route path='/404' element={<NotFound />}></Route>
            </Routes>
          </Suspense>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;
